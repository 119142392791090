<template>
  <div class="d-flex justify-content-center">
    <d-button
        text="general.actions.book"
        class="d-btn-sm btn d-btn-primary-outline font-text-title"
        icon="fa fa-user-plus mr-1"
    />
  </div>
</template>
<script>
  export default {

  }
</script>
